import React, { useState, useRef } from "react";
import Link from "next/link";

import HoverPopover, { positionRight } from "@components/HoverPopover/HoverPopover";
import { useLazyWorkspaceUrl } from "@hooks/url-hooks";

import "./TabletPreviewCollectionLinks.scss";

const TabletPreviewCollectionLinks = ({ collections = [] }) => {
    const [showDialog, setShowDialog] = useState(false);
    const moreLinkRef = useRef();
    const generateWorkspaceUrl = useLazyWorkspaceUrl();

    const [mainCollection, ...otherCollections] = collections;
    const collectionUrl = generateWorkspaceUrl("collection/[collection]", `collection/${mainCollection?.id}`);

    const hasMultipleCollections = otherCollections.length !== 0;
    return (
        <div className="tablet-preview-collection-links">
            {!hasMultipleCollections && !!mainCollection && (
                <Link href={collectionUrl.href} as={collectionUrl.as}>
                    <a>
                        <span className="collection-name">{mainCollection.name}</span>
                    </a>
                </Link>
            )}
            {hasMultipleCollections && (
                <>
                    <Link href={collectionUrl.href} as={collectionUrl.as}>
                        <a>
                            <span className="collection-name">{mainCollection.name}</span>
                        </a>
                    </Link>
                    <span className="more-links-join">&</span>
                    <div className="more-links" ref={moreLinkRef}>
                        {otherCollections.length} more
                    </div>
                </>
            )}

            <HoverPopover targetRef={moreLinkRef} position={positionRight}>
                <div className="more-links-section">
                    <h3>Other collections</h3>
                    <ul>
                        {otherCollections.map(({ id, name }) => {
                            const collectionUrl = generateWorkspaceUrl(
                                "collection/[collection]",
                                `collection/${id}`
                            );

                            return (
                                <li key={id}>
                                    <Link href={collectionUrl.href} as={collectionUrl.as}>
                                        <a>
                                            <span className="collection-name">{name}</span>
                                        </a>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </HoverPopover>
        </div>
    );
};

export default TabletPreviewCollectionLinks;
