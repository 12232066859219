import React from "react";
import "./Badge.scss";

const Badge = ({ children, type = "attention" }) => {
    return (
        <div className="scribe-badge" data-type={type}>
            {children}
        </div>
    );
};

export default Badge;
