import React from "react";
import Link from "next/link";

import PreviewMetadata from "@components/PreviewMetadata/PreviewMetadata";
import TabletPreviewCollectionLinks from "../TabletPreviewCollectionLinks/TabletPreviewCollectionLinks";
import { useWorkspaceUrl } from "@hooks/url-hooks";

import "./TabletPreviewCondensed.scss";

const TabletPreviewCondensed = ({ id, collections, title, createdAt, updatedAt, owner }) => {
    const tabletUrl = useWorkspaceUrl("page/[page]/edit", `page/${id}/edit`);

    return (
        <div className="tablet-preview-condensed">
            <div className="tablet-details">
                <div className="collection-links-container">
                    <TabletPreviewCollectionLinks collections={collections} />
                </div>

                <Link href={tabletUrl.href} as={tabletUrl.as}>
                    <a>
                        <h2 className="tablet-title">{title}</h2>
                    </a>
                </Link>
                <div className="preview-metadata-container">
                    <PreviewMetadata updatedAt={updatedAt} createdAt={createdAt} owner={owner} />
                </div>
            </div>
        </div>
    );
};

export default TabletPreviewCondensed;
