import React from "react";
import dynamic from "next/dynamic";

import Tooltip from "@components/Tooltip/Tooltip";

import "./StatSymbol.scss";

const DynamicStatCounter = dynamic(() => import("./StatCounter/StatCounter"), { ssr: false });
const DynamicStatRing = dynamic(() => import("./StatRing/StatRing"), { ssr: false });

const StatSymbol = ({ views = [], status = {}, className }) => {
    let viewsToCount = views;
    const isOutdated = status?.status === "outdated";
    if (isOutdated) {
        const outdatedAt = new Date(status?.changedAt);
        const viewsSinceOutdated = views.filter(({ viewedAt }) => {
            return new Date(viewedAt) > outdatedAt;
        });
        viewsToCount = viewsSinceOutdated;
    }

    const connotation = isOutdated ? "negative" : "positive";
    const label = isOutdated
        ? `${viewsToCount.length} views since being outdated`
        : `Viewed ${viewsToCount.length} times`;
    return (
        <div className="stat-symbol">
            {/* <DynamicStatRing type="question" /> */}
            {viewsToCount.length !== 0 && (
                <Tooltip label={label}>
                    <div className={`stat-counter-container ${className}`}>
                        <DynamicStatCounter count={viewsToCount.length} connotation={connotation} />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default StatSymbol;
