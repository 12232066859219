import React from "react";
import Link from "next/link";

import StatSymbol from "@components/StatSymbol/StatSymbol";
import PreviewMetadata from "@components/PreviewMetadata/PreviewMetadata";
import Badge from "@components/Badge/Badge";
import TabletPreviewCollectionLinks from "../TabletPreviewCollectionLinks/TabletPreviewCollectionLinks";

import { useWorkspaceUrl } from "@hooks/url-hooks";

import "./TabletPreviewNormal.scss";

const TabletPreviewNormal = ({
    id,
    collections,
    title,
    createdAt,
    updatedAt,
    owner,
    views,
    currentStatus = {},
    showSymbol = true,
}) => {
    // let badgeType = "attention";
    // if (currentStatus.status === "outdated") badgeType = "danger";
    const tabletUrl = useWorkspaceUrl("page/[page]/edit", `page/${id}/edit`);

    return (
        <div className="tablet-preview-normal">
            {showSymbol && (
                <div className="symbol-container">
                    <StatSymbol views={views} status={currentStatus} className="stat-symbol-layout" />
                </div>
            )}

            <div className="tablet-details">
                <div className="collection-links-container">
                    <TabletPreviewCollectionLinks collections={collections} />
                </div>

                <Link href={tabletUrl.href} as={tabletUrl.as}>
                    <a>
                        <h2 className="tablet-title">{title}</h2>
                    </a>
                </Link>
                <div className="preview-metadata-container">
                    <PreviewMetadata updatedAt={updatedAt} createdAt={createdAt} owner={owner} />
                </div>
            </div>
            {/* 
            {!!currentStatus.status && (
                <div className="badge-container">
                    <Badge type={badgeType}>{currentStatus.status}</Badge>
                </div>
            )} */}
        </div>
    );
};

export default TabletPreviewNormal;
