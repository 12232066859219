import React, { useState, useRef, forwardRef, useMemo, useEffect } from "react";
import Portal from "@reach/portal";
import { useRect } from "@reach/rect";
import { useForkedRef } from "@reach/utils";

import { useSimulateTabNavigationForReactTree } from "@hooks/accessability-hooks";
import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";
import { throttleDebounce } from "@modules/helpers";
import { useNode, useResponsiveRect } from "@hooks/element-hooks";

import "./Popover.scss";

/* Close event - sometimes we want the popover to close as soon as the mouse presses down
to avoid any unwanted sideeffects whilst the mouse is held. For example on the editor we want
the command popover to close immediately because mousedown causes the selection to change so whilst
it's held down the contents of the popover will change. The NavBar profile links popover we want to
stay open whilst the mouse is down because buttons need an onclick handler to work with the keyboard
*/

////////////////////////////////////////////////////////////////////////////////
// Popover

// If the target ref is an element that can recieve focus and it is currently focused
// then this will also take care of making sure the next tab takes you to the popover (if
// it has focusable elements)

const Popover = forwardRef(function Popover(props, ref) {
    const { preventOutsideInteraction = false, ...rest } = props;
    const { show, onClose } = rest;
    return (
        <Portal>
            {preventOutsideInteraction && (
                <div
                    data-popover-overlay
                    onClick={onClose}
                    onTouchStart={(e) => e.preventDefault()}
                    onMouseDown={(e) => e.preventDefault()}
                    data-show={show}
                ></div>
            )}
            <PopoverImpl ref={ref} {...rest} />
        </Portal>
    );
});

Popover.displayName = "Popover";

export default Popover;

////////////////////////////////////////////////////////////////////////////////
// PopoverImpl

// Popover is conditionally rendered so we can't start measuring until it shows
// up, so useRect needs to live down here not up in Popover
const PopoverImpl = forwardRef(function PopoverImpl(
    {
        targetRef,
        targetRect: overridenTargetRect,
        position = getPositioner({ bindToViewport: true }),
        style,
        show = false,
        fixed = false,
        positionDeps = [],
        children,
        matchWidth = false,
        focusable = true,
        onClose,
        closeEvent = "mousedown",
        ...rest
    },
    forwardedRef
) {
    const [popoverNode, popoverRef] = useNode();
    const ref = useForkedRef(popoverRef, forwardedRef);
    const targetRefRect = useResponsiveRect(targetRef, show && targetRef);
    const targetRect = overridenTargetRect || targetRefRect;

    useIsomorphicLayoutEffect(() => {
        if (!show || !targetRect || !popoverNode) return;

        const rect = popoverNode?.getBoundingClientRect();
        const popoverRect = {
            width: rect?.width,
            height: rect.height,
            top: rect.top,
            bottom: rect.bottom,
            left: rect.left,
            right: rect.right,
            contentHeight: popoverNode?.firstElementChild?.offsetHeight,
        };

        function handleResize(e) {
            // Don't need to do popover rect since we only use it's height and that won't change on resize
            const targetRect = overridenTargetRect || targetRef?.current?.getBoundingClientRect();

            const newPosition = position(targetRect, popoverRect);

            for (const [key, value] of Object.entries(newPosition)) {
                if (typeof value === "number") popoverNode.style[key] = `${value}px`;
                else popoverNode.style[key] = value;
            }
        }

        const throttledResize = throttleDebounce(handleResize, 300);

        window.addEventListener("resize", throttledResize);
        handleResize();

        return () => window.removeEventListener("resize", throttledResize);
    }, [show, targetRect, popoverNode, ...positionDeps]);

    useEffect(() => {
        if (!show) return;

        function handleClick(event) {
            if (!event.target.closest("[data-reach-popover-content]")) {
                onClose && onClose();
            }
        }

        document.addEventListener(closeEvent, handleClick);
        return () => document.removeEventListener(closeEvent, handleClick);
    }, [show]);

    useEffect(() => {
        if (!show) return;

        function handleKeyDown(event) {
            if (event.key === "Escape") {
                event.stopPropagation();
                onClose && onClose();
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [show]);

    useSimulateTabNavigationForReactTree(targetRef?.current, popoverNode, { skip: !focusable });
    return (
        <div
            data-reach-popover
            data-is-visible={show}
            data-fixed={fixed}
            ref={ref}
            style={{
                ...style,
                position: fixed ? "fixed" : "absolute",
                width: matchWidth ? targetRef?.current?.clientWidth : null,
            }}
            {...rest}
        >
            <div data-reach-popover-content>{children}</div>
        </div>
    );
});

PopoverImpl.displayName = "PopoverImpl";

////////////////////////////////////////////////////////////////////////////////

export function getPositioner(options = {}) {
    const {
        xPreference = "left",
        yPreference = "bottom",
        bindToViewport = false,
        adaptive = true,
        offsetX = 0,
        offsetY = 0,
    } = options;

    if (bindToViewport) return positionBounded({ xPreference, yPreference, adaptive, offsetX, offsetY });
    //else if (preference === "bottom") return positionBottom(options);
    //else if (preference === "")
}

const positionDefault = ({ offset }) => (targetRect, popoverRect) => {
    const collisions = getCollisions(targetRect, popoverRect);
    const { directionUp, directionRight } = getUnboundedPlacement(collisions);

    return {
        left: directionRight
            ? `${targetRect.left + targetRect.width + window.pageXOffset + offset}px`
            : `${targetRect.left + window.pageXOffset + offset}px`,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset - offset}px`
            : `${targetRect.top + targetRect.height + window.pageYOffset + offset}px`,
    };
};

export function positionMatchWidth(targetRect, popoverRect) {
    const collisions = getCollisions(targetRect, popoverRect);
    const { directionUp } = getUnboundedPlacement(collisions);

    return {
        width: targetRect.width,
        left: targetRect.left,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    };
}

export function positionRight(targetRect, popoverRect) {
    const collisions = getCollisions(targetRect, popoverRect);
    const { directionUp } = getUnboundedPlacement(collisions);

    return {
        left: targetRect.left + targetRect.width + 8,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + window.pageYOffset}px`,
    };
}

const positionBounded = ({ xPreference, yPreference, adaptive, offsetX, offsetY }) => (
    targetRect,
    popoverRect
) => {
    const { bottom, top, right, left } = getCollisions(targetRect, popoverRect, 8, 8);

    const distanceToTop = targetRect.top;
    const distanceToBottom = window.innerHeight - targetRect.bottom;

    function positionBottom() {
        return {
            top: targetRect.top + targetRect.height + window.pageYOffset + offsetY,
            bottom: "unset",
        };
    }

    function positionBoundedBottom() {
        return {
            top: targetRect.top + targetRect.height + window.pageYOffset + offsetY,
            bottom: -window.pageYOffset + offsetY,
        };
    }

    function positionTop() {
        return {
            top: targetRect.top - popoverRect.contentHeight + window.pageYOffset - offsetY,
            bottom: "unset",
        };
    }

    function positionBoundedTop() {
        return {
            top: window.pageYOffset + offsetY,
            bottom: -window.pageYOffset + distanceToBottom + targetRect.height + offsetY,
        };
    }

    function positionRight() {
        return {
            left: window.pageXOffset + targetRect.left + targetRect.width + offsetX,
            right: "unset",
        };
    }

    function positionRightmost() {
        return {
            left: "unset",
            right: window.pageXOffset - 16,
        };
    }

    function positionLeft() {
        return {
            left: window.pageXOffset + targetRect.left + offsetX,
            right: "unset",
        };
    }

    let verticalPos;
    let horizontalPos;

    if (yPreference === "largest" && distanceToBottom > distanceToTop && !bottom)
        verticalPos = positionBottom();
    else if (yPreference === "largest" && distanceToBottom > distanceToTop && bottom)
        verticalPos = positionBoundedBottom();
    else if (yPreference === "largest" && distanceToTop > distanceToBottom && !top)
        verticalPos = positionTop();
    else if (yPreference === "largest" && distanceToTop > distanceToBottom && top)
        verticalPos = positionBoundedTop();
    else if (yPreference === "bottom" && !bottom) verticalPos = positionBottom();
    else if (yPreference === "top" && !bottom && top) verticalPos = positionBottom();
    else if (yPreference === "bottom" && !adaptive) verticalPos = positionBoundedBottom();
    else if (top && bottom && distanceToBottom > distanceToTop) verticalPos = positionBoundedBottom();
    else if (yPreference === "top" && !top) verticalPos = positionTop();
    else if (yPreference === "bottom" && !top && bottom) verticalPos = positionTop();
    else if (yPreference === "top" && !adaptive) verticalPos = positionBoundedTop();
    else if (top && bottom && distanceToTop > distanceToBottom) verticalPos = positionBoundedTop();

    if (xPreference === "left" && !left) horizontalPos = positionLeft();
    else if (left) horizontalPos = positionRightmost(); // Collision on left means we already collided on right

    if (xPreference === "right" && !right) horizontalPos = positionRight();
    else if (xPreference === "right" && right) horizontalPos = positionRightmost();

    return {
        ...verticalPos,
        ...horizontalPos,
    };
};

function getCollisions(targetRect, popoverRect, offsetX = 0, offsetY = 0) {
    const collisions = {
        top: targetRect.top - popoverRect.contentHeight - offsetY <= 0,
        right: targetRect.right + popoverRect.width + offsetX >= window.innerWidth,
        bottom: window.innerHeight <= targetRect.bottom + popoverRect.contentHeight + offsetY,
        left: targetRect.left + popoverRect.width + offsetX >= window.innerWidth,
    };

    return collisions;
}

function getUnboundedPlacement({ top, left, bottom, right }) {
    const directionRight = right && !left;
    const directionUp = bottom && !top;
    const directionDown = !directionUp;
    const directionLeft = !directionRight;

    return { directionRight, directionUp, directionDown, directionLeft };
}

// Finish this another time
// export function positionHorizontalCenter(targetRect, popoverRect) {
//   const targetCenter = targetRect.width / 2 + targetRect.left;
//   const popoverHalf = popoverRect.width / 2;

//   const collisions = {
//     right: window.innerWidth < targetCenter - popoverHalf,
//     left: targetCenter - popoverHalf < 0
//     // top:
//     // bottom:
//   };

//   return {
//     left: collisions.right
//       ? `${targetRect.right - popoverRect.width + window.pageXOffset}px`
//       : collisions.left ? `` : ``
//   };
// }
