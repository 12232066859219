import React from "react";
import Img from "react-image";

import { AvatarEditOverlay } from "./AvatarEditOverlay/AvatarEditOverlay";

import "./Avatar.scss";

// An SVG is used as it allows the text size to be proportional to the size of the container
const AvatarPlaceholder = ({ placeholderText }) => (
    <svg className="placeholder-avatar" viewBox="0 0 16 16">
        <text x="50%" y="55%" className="placeholder-text">
            {placeholderText}
        </text>
    </svg>
);

const Avatar = ({ placeholderText, imageUrl, square = false, children }) => {
    return (
        <div data-avatar data-square={square}>
            {!imageUrl && <AvatarPlaceholder placeholderText={placeholderText} />}
            {imageUrl && (
                <Img src={imageUrl} unloader={<AvatarPlaceholder placeholderText={placeholderText} />} />
            )}
            {children}
        </div>
    );
};

export default Avatar;
export { AvatarEditOverlay };
