import React from "react";
import Router from "next/router";

import TabletPreviewCondensed from "./TabletPreviewCondensed/TabletPreviewCondensed";
import TabletPreviewNormal from "./TabletPreviewNormal/TabletPreviewNormal";
import TabletPreviewExpanded from "./TabletPreviewExpanded/TabletPreviewExpanded";
import Badge from "@components/Badge/Badge";

import { getTabletStatus } from "@modules/tablet-helpers";
import { useWorkspaceUrl } from "@hooks/url-hooks";

import "./TabletPreview.scss";

const TabletPreview = ({ type = "normal", contained, ...rest }) => {
    const { currentStatus, createdAt, updatedAt, id, shared } = rest;
    const tabletUrl = useWorkspaceUrl("page/[page]/edit", `page/${id}/edit`);
    const badgeState = getTabletStatus(currentStatus?.status, createdAt, updatedAt);
    const title = rest.title || rest?.draft?.title;

    function handleClick(event) {
        if (!event.target.closest(".preview-metadata") && contained) {
            Router.push(tabletUrl.href, tabletUrl.as);
        }
    }

    return (
        <div className="tablet-preview" data-contained={contained} onClick={handleClick}>
            {type === "condensed" && <TabletPreviewCondensed {...rest} title={title} />}
            {type === "normal" && <TabletPreviewNormal {...rest} title={title} />}
            {type === "expanded" && <TabletPreviewExpanded {...rest} title={title} />}

            {!!badgeState.status && shared && (
                <div className="badge-container">
                    <Badge type={badgeState.type}>{badgeState.status}</Badge>
                </div>
            )}
        </div>
    );
};

export default TabletPreview;
