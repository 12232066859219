import React, { useState, useRef, forwardRef, useMemo } from "react";
import Portal from "@reach/portal";
import { useRect } from "@reach/rect";
import { useForkedRef } from "@reach/utils";

import { useMouseTracking, useIsHovering } from "@hooks/element-hooks";

import "./HoverPopover.scss";

////////////////////////////////////////////////////////////////////////////////
// Popover

// If the target ref is an element that can recieve focus and it is currently focused
// then this will also take care of making sure the next tab takes you to the popover (if
// it has focusable elements)

const HoverPopover = forwardRef(function HoverPopover(props, ref) {
    return (
        <Portal>
            <PopoverImpl ref={ref} {...props} />
        </Portal>
    );
});

HoverPopover.displayName = "HoverPopover";

export default HoverPopover;

////////////////////////////////////////////////////////////////////////////////
// PopoverImpl

// Popover is conditionally rendered so we can't start measuring until it shows
// up, so useRect needs to live down here not up in Popover
const PopoverImpl = forwardRef(function PopoverImpl(
    { targetRef, position = positionDefault, style, mode = "hover", show = false, fixed = false, ...rest },
    forwardedRef
) {
    const popoverRef = useRef();
    const ref = useForkedRef(popoverRef, forwardedRef);

    const [, isStillHoveringOverTarget] = useIsHovering(targetRef, 100);
    const [, isStillHoveringOverPopover] = useIsHovering(popoverRef, 200, { forwards: false });
    const isApproachingPopover = useMouseTracking(popoverRef, isStillHoveringOverTarget);

    // We can override the isvisible behaviour with the show prop
    const isVisible =
        mode === "hover"
            ? isStillHoveringOverTarget || isApproachingPopover || isStillHoveringOverPopover
            : show;

    const popoverRect = useMemo(() => popoverRef.current?.getBoundingClientRect(), [popoverRef.current]);

    return (
        <div
            data-reach-hover-popover
            data-is-visible={!!isVisible}
            ref={ref}
            style={{
                ...style,
                position: fixed ? "fixed" : "absolute",
                ...getStyles(position, targetRef, popoverRect, isVisible),
            }}
            {...rest}
        />
    );
});

PopoverImpl.displayName = "PopoverImpl";

////////////////////////////////////////////////////////////////////////////////

function isVisible(popoverRect, isHoveringOverTarget) {
    const needToMeasurePopup = !popoverRect;
    if (needToMeasurePopup || !isHoveringOverTarget) {
        return false;
    } else {
        return true;
    }
}

function getStyles(position, targetRef, popoverRect, isHoveringOverTarget) {
    if (!isVisible(popoverRect, isHoveringOverTarget)) return {};

    const targetRect = targetRef.current.getBoundingClientRect();
    return position(targetRect, popoverRect);
}

export function positionDefault(targetRect, popoverRect) {
    const { directionUp, directionRight } = getCollisions(targetRect, popoverRect);
    return {
        left: directionRight
            ? `${targetRect.right - popoverRect.width + window.pageXOffset}px`
            : `${targetRect.left + window.pageXOffset}px`,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    };
}

export function positionMatchWidth(targetRect, popoverRect) {
    const { directionUp } = getCollisions(targetRect, popoverRect);
    return {
        width: targetRect.width,
        left: targetRect.left,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    };
}

export function positionRight(targetRect, popoverRect) {
    const { directionUp } = getCollisions(targetRect, popoverRect);
    return {
        left: targetRect.left + targetRect.width + 8,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + window.pageYOffset}px`,
    };
}

export function positionBottom(targetRect, popoverRect) {
    const { directionUp } = getCollisions(targetRect, popoverRect);
    return {
        left: targetRect.left,
        top: directionUp
            ? `${targetRect.top - popoverRect.height + window.pageYOffset}px`
            : `${targetRect.top + targetRect.height + window.pageYOffset + 16}px`,
    };
}

// Finish this another time
// export function positionHorizontalCenter(targetRect, popoverRect) {
//   const targetCenter = targetRect.width / 2 + targetRect.left;
//   const popoverHalf = popoverRect.width / 2;

//   const collisions = {
//     right: window.innerWidth < targetCenter - popoverHalf,
//     left: targetCenter - popoverHalf < 0
//     // top:
//     // bottom:
//   };

//   return {
//     left: collisions.right
//       ? `${targetRect.right - popoverRect.width + window.pageXOffset}px`
//       : collisions.left ? `` : ``
//   };
// }

export function getCollisions(targetRect, popoverRect, offsetLeft = 0, offsetBottom = 0) {
    const collisions = {
        top: targetRect.top - popoverRect.height < 0,
        right: window.innerWidth < targetRect.left + popoverRect.width - offsetLeft,
        bottom: window.innerHeight < targetRect.bottom + popoverRect.height - offsetBottom,
        left: targetRect.left - popoverRect.width < 0,
    };

    const directionRight = collisions.right && !collisions.left;
    const directionUp = collisions.bottom && !collisions.top;

    return { directionRight, directionUp };
}
