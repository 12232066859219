import React, { useRef, useState, useEffect } from "react";
import tabbable from "tabbable";

// This hook scopes the tab order to the React element tree, instead of the DOM tree.
// This way, when the user navigates with tab from the triggerRef, the tab order moves into the
// popup, and then out of the popup back to the rest of the document.
export function useSimulateTabNavigationForReactTree(triggerNode, targetNode, { skip = false } = {}) {
    const doc = triggerNode?.ownerDocument; // maybe in devtools
    function handleKeyDown(event) {
        if (event.key !== "Tab") return;

        const targetContainsTabbableEl = tabbable(targetNode).length !== 0;

        if (!targetContainsTabbableEl) return;

        // Check if we have tabbed backwards, otherwise we have tabbed forwards
        if (event.shiftKey) {
            if (shiftTabbedFromElementAfterTrigger(event)) {
                focusLastTabbableInTarget(event);
            } else if (shiftTabbedOutOfTarget(event)) {
                focusTriggerNode(event);
            } else if (shiftTabbedToBrowserChrome(event)) {
                disableTabbablesInTarget(event);
            }
        } else {
            if (tabbedFromTriggerToTarget(event)) {
                focusFirstTargetTabbable(event);
            } else if (tabbedOutOfTarget(event)) {
                focusTabbableAfterTrigger(event);
            } else if (tabbedToBrowserChrome(event)) {
                disableTabbablesInTarget(event);
            }
        }
    }

    useEffect(() => {
        if (skip || !doc || !targetNode) return;

        doc.addEventListener("keydown", handleKeyDown);
        return () => doc.removeEventListener("keydown", handleKeyDown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, targetNode, triggerNode]);

    function getElementAfterTrigger() {
        const elements = tabbable(doc);
        const targetIndex = elements.indexOf(triggerNode);
        return elements[targetIndex + 1];
    }

    function tabbedFromTriggerToTarget() {
        return triggerNode === document.activeElement;
    }

    function focusFirstTargetTabbable(event) {
        const elements = tabbable(targetNode);
        if (elements[0]) {
            event.preventDefault();
            elements[0].focus();
        }
    }

    function tabbedOutOfTarget(event) {
        const inTarget = targetNode.contains(document.activeElement);
        if (inTarget) {
            const elements = tabbable(targetNode);
            return elements[elements.length - 1] === document.activeElement;
        }
    }
    function focusTabbableAfterTrigger(event) {
        const elementAfterTrigger = getElementAfterTrigger();
        if (elementAfterTrigger) {
            event.preventDefault();
            elementAfterTrigger.focus();
        }
    }

    function shiftTabbedFromElementAfterTrigger(event) {
        if (!event.shiftKey) return;
        const elementAfterTrigger = getElementAfterTrigger();
        return getElementTabbedFrom() === elementAfterTrigger;
    }
    function focusLastTabbableInTarget(event) {
        const elements = tabbable(targetNode);
        const last = elements[elements.length - 1];
        if (last) {
            event.preventDefault();
            last.focus();
        }
    }

    function shiftTabbedOutOfTarget(event) {
        const elements = tabbable(targetNode);
        return elements.length === 0 ? false : getElementTabbedFrom() === elements[0];
    }

    function focusTriggerNode(event) {
        event.preventDefault();
        triggerNode.focus();
    }

    function tabbedToBrowserChrome(event) {
        const elements = tabbable(doc).filter((element) => !targetNode.contains(element));
        return getElementTabbedFrom() === elements[elements.length - 1];
    }

    function shiftTabbedToBrowserChrome(event) {
        // we're assuming the target element will never contain the first tabbable
        // element, and it better not, because the trigger needs to be tabbable!
        return getElementTabbedFrom() === tabbable(doc)[0];
    }

    let restoreTabIndexTuples = [];
    function disableTabbablesInTarget() {
        const tabbableChildrenEls = tabbable(targetNode);

        tabbableChildrenEls.forEach((element) => {
            restoreTabIndexTuples.push([element, element.tabIndex]);
            element.tabIndex = -1;
        });
        doc.addEventListener("focusin", enableTabbablesInTarget);
    }
    function enableTabbablesInTarget(event) {
        doc.removeEventListener("focusin", enableTabbablesInTarget);
        restoreTabIndexTuples.forEach(([element, tabIndex]) => {
            element.tabIndex = tabIndex;
        });
    }

    function getElementTabbedFrom(event) {
        return event?.target;
    }
}
