import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import algoliasearch from "algoliasearch/lite";

import { useWorkspace } from "@hooks/url-hooks";

const GENERATE_SEARCH_KEY = gql`
    mutation generateSearchKey($input: GenerateSearchKeyInput!) {
        generateSearchKey(input: $input)
    }
`;

/**
 * Creates a search index that you can use to search for tablets via a number of different
 * attributes (e.g. name, wikis, collections, ect). These search queries are all scoped to
 * the current workspace.
 */
export function useSearch(indexName = "content") {
    const [searchIndex, setSearchIndex] = useState();
    const workspaceId = useWorkspace();
    const [generateSearchKey, { data: generateSearchKeyRes }] = useMutation(GENERATE_SEARCH_KEY);

    useEffect(() => {
        if (!workspaceId) return;

        generateSearchKey({ variables: { input: { workspace: workspaceId } } });
    }, [workspaceId]);

    useEffect(() => {
        if (!generateSearchKeyRes) return;
        const searchKey = generateSearchKeyRes.generateSearchKey;
        const client = algoliasearch("GS3DOFKEZZ", searchKey);
        const index = client.initIndex(indexName);

        setSearchIndex(index);
    }, [generateSearchKeyRes]);

    return searchIndex;
}
