import React from "react";
import Link from "next/link";

import StatSymbol from "@components/StatSymbol/StatSymbol";
import PreviewMetadata from "@components/PreviewMetadata/PreviewMetadata";
import TabletPreviewCollectionLinks from "../TabletPreviewCollectionLinks/TabletPreviewCollectionLinks";
import { useWorkspaceUrl } from "@hooks/url-hooks";

import "./TabletPreviewExpanded.scss";

const TabletPreviewExpanded = ({
    id,
    collections,
    title,
    createdAt,
    updatedAt,
    owner,
    preview,
    views,
    currentStatus,
    type = "normal",
    showSymbol = true,
}) => {
    const tabletUrl = useWorkspaceUrl("page/[page]/edit", `page/${id}/edit`);

    return (
        <article className="tablet-preview-expanded">
            <div className="tablet-header-section">
                {showSymbol && (
                    <div className="symbol-container">
                        <StatSymbol views={views} status={currentStatus} className="stat-symbol-layout" />
                    </div>
                )}
                <div className="collection-links-container">
                    <TabletPreviewCollectionLinks collections={collections} />
                </div>

                <Link href={tabletUrl.href} as={tabletUrl.as}>
                    <a className="tablet-title-container">
                        <h2 className="tablet-title">{title}</h2>
                    </a>
                </Link>
            </div>

            {!!preview && (
                <Link href={tabletUrl.href} as={tabletUrl.as}>
                    <a>
                        <div className="tablet-preview-text">{preview}</div>
                    </a>
                </Link>
            )}

            <div className="preview-metadata-container">
                <PreviewMetadata updatedAt={updatedAt} createdAt={createdAt} owner={owner} />
            </div>
        </article>
    );
};

export default TabletPreviewExpanded;
