import React, { useRef, useState, useEffect } from "react";
import { arraysEqual } from "@modules/helpers";
import { useIsomorphicLayoutEffect } from "@hooks/use-isomorphic-layout-effect";

export function usePrevious(value, defaultValue) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current || defaultValue;
}

export function useDiff(value) {
    const initialValue = useRef(value);
    const [hasDelta, setHasDelta] = useState(false);

    useEffect(() => {
        if (Array.isArray(value) && arraysEqual(initialValue.current, value)) setHasDelta(false);
        else if (initialValue.current === value) setHasDelta(false);
        else setHasDelta(true);
    }, [value]);

    return hasDelta;
}

/*
 * Welcome to @reach/auto-id!
 * Let's see if we can make sense of why this hook exists and its
 * implementation.
 *
 * Some background:
 *   1. Accessibiliy APIs rely heavily on element IDs
 *   2. Requiring developers to put IDs on every element in Reach UI is both
 *      cumbersome and error-prone
 *   3. With a component model, we can generate IDs for them!
 *
 * Solution 1: Generate random IDs.
 *
 * This works great as long as you don't server render your app. When React (in
 * the client) tries to reuse the markup from the server, the IDs won't match
 * and React will then recreate the entire DOM tree.
 *
 * Solution 2: Increment an integer
 *
 * This sounds great. Since we're rendering the exact same tree on the server
 * and client, we can increment a counter and get a deterministic result between
 * client and server. Also, JS integers can go up to nine-quadrillion. I'm
 * pretty sure the tab will be closed before an app never needs
 * 10 quadrillion IDs!
 *
 * Problem solved, right?
 *
 * Ah, but there's a catch! React's concurrent rendering makes this approach
 * non-deterministic. While the client and server will end up with the same
 * elements in the end, depending on suspense boundaries (and possibly some user
 * input during the initial render) the incrementing integers won't always match
 * up.
 *
 * Solution 3: Don't use IDs at all on the server; patch after first render.
 *
 * What we've done here is solution 2 with some tricks. With this approach, the
 * ID returned is an empty string on the first render. This way the server and
 * client have the same markup no matter how wild the concurrent rendering may
 * have gotten.
 *
 * After the render, we patch up the components with an incremented ID. This
 * causes a double render on any components with `useId`. Shouldn't be a problem
 * since the components using this hook should be small, and we're only updating
 * the ID attribute on the DOM, nothing big is happening.
 *
 * It doesn't have to be an incremented number, though--we could do generate
 * random strings instead, but incrementing a number is probably the cheapest
 * thing we can do.
 *
 * Additionally, we only do this patchup on the very first client render ever.
 * Any calls to `useId` that happen dynamically in the client will be
 * populated immediately with a value. So, we only get the double render after
 * server hydration and never again, SO BACK OFF ALRIGHT?
 */
let serverHandoffComplete = false;
let id = 0;
const genId = () => ++id;

/**
 * useId
 *
 * Autogenerate IDs to facilitate WAI-ARIA and server rendering.
 *
 * Note: The returned ID will initially be `null` and will update after a
 * component mounts. Users may need to supply their own ID if they need
 * consistent values for SSR.
 *
 * @see Docs https://reacttraining.com/reach-ui/auto-id
 */
export function useId(idFromProps) {
    /*
     * If this instance isn't part of the initial render, we don't have to do the
     * double render/patch-up dance. We can just generate the ID and return it.
     */
    const initialId = idFromProps || (serverHandoffComplete ? genId() : null);

    const [id, setId] = useState(initialId);

    useIsomorphicLayoutEffect(() => {
        if (id === null) {
            /*
             * Patch the ID after render. We do this in `useLayoutEffect` to avoid any
             * rendering flicker, though it'll make the first render slower (unlikely
             * to matter, but you're welcome to measure your app and let us know if
             * it's a problem).
             */
            setId(genId());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serverHandoffComplete === false) {
            /*
             * Flag all future uses of `useId` to skip the update dance. This is in
             * `useEffect` because it goes after `useLayoutEffect`, ensuring we don't
             * accidentally bail out of the patch-up dance prematurely.
             */
            serverHandoffComplete = true;
        }
    }, []);
    return id != null ? String(id) : undefined;
}

/**
 * Allows us to mimic a boolean value with a delay which you can turn on and off for both edges
 * @param {boolean} value The value that we would like to mimic in a delayed fashion
 * @param {number} timeout The time we should wait before turning the delay value on or off
 * @param {object} options Tune whether we want the timeout when the value turns on or off
 */
export function useDelayedSwitch(value, timeout = 1000, { delayOn = true, delayOff = true } = {}) {
    const [delayedValue, setDelayedValue] = useState();
    const [transitioning, setTransitioning] = useState(false);

    useEffect(() => {
        if (value && delayOn) {
            setTransitioning(true);
            setTimeout(() => {
                setTransitioning(false);
                setDelayedValue(value);
            }, timeout);
        } else if (!value && delayOff) {
            setTransitioning(true);
            setTimeout(() => {
                setTransitioning(false);
                setDelayedValue(value);
            }, timeout);
        } else {
            setDelayedValue(value);
        }
    }, [value, delayOn, delayOff]);

    return [delayedValue, transitioning];
}

export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}
