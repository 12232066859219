import React from "react";
import Link from "next/link";

import { formatDate } from "@modules/date-time";
import { useWorkspaceUrl } from "@hooks/url-hooks";

import "./PreviewMetadata.scss";

const PreviewMetadata = ({ createdAt, updatedAt, owner = {} }) => {
    const { id, firstName = "", lastName = "" } = owner;
    const profileUrl = useWorkspaceUrl("profile/[...slug]", `profile/${id}`);

    const hasBeenUpdated = updatedAt > createdAt;

    return (
        <ul className="preview-metadata">
            {!hasBeenUpdated && (
                <li>
                    <time dateTime={createdAt.toISOString()}>{formatDate(createdAt)}</time>
                </li>
            )}
            {hasBeenUpdated && (
                <li>
                    Updated <time dateTime={updatedAt.toISOString()}>{formatDate(updatedAt)}</time>
                </li>
            )}
            {firstName && (
                <li>
                    <Link href={profileUrl.href} as={profileUrl.as}>
                        <a className="author">
                            {firstName} {lastName}
                        </a>
                    </Link>
                </li>
            )}
        </ul>
    );
};

export default PreviewMetadata;
